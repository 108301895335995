import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_QR_CODE_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from '../../constants/actionTypes';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function usersReducer(state = initialState.user, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case LOGIN_SUCCESS:
      newState.isLoggedIn = true;
      return newState;
    case LOGOUT_SUCCESS:
      newState.isLoggedIn = false;
      return newState;
    case LOGIN_FAILURE:
      newState.statusCode = action.error.status;
      return newState;
    case GET_USER_INFO_SUCCESS:
      newState.email = action.data.Email;
      newState.remainingTraffic =
        (action.data.CurrentSubscription) ?
          action.data.CurrentSubscription.RemainingQuota : initialState.user.remainingTraffic;
      newState.isEmailConfirmed = action.data.EmailConfirmed;
      newState.isPhoneConfirmed = action.data.PhoneConfirmed;
      return newState;

    case GET_USER_QR_CODE_SUCCESS:
      newState.qrCode.url = action.data.URL;
      newState.qrCode.imageData = action.data.DataURL;;
      return newState;

    case REGISTER_SUCCESS:
      newState.isRegistered = true;
      return newState;
    case REGISTER_FAILURE:
      newState.registerFailureServerError = action.error.message;
      return newState;

    default:
      return state;
  }
}
