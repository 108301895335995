export const BUY_PLAN_START = 'BUY_PLAN_START';
export const BUY_PLAN_SUCCESS = 'BUY_PLAN_SUCCESS';
export const BUY_PLAN_FAILURE = 'BUY_PLAN_FAILURE';
export const GET_PLAN_START = 'GET_PLAN_START';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';
export const GET_PLAN_HISTORY_START = 'GET_PLAN_HISTORY_START';
export const GET_PLAN_HISTORY_SUCCESS = 'GET_PLAN_HISTORY_SUCCESS';
export const GET_PLAN_HISTORY_FAILURE = 'GET_PLAN_HISTORY_FAILURE';
export const GET_USER_INFO_START = 'GET_USER_INFO_START';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const GET_USER_QR_CODE_START = 'GET_USER_QR_CODE_START';
export const GET_USER_QR_CODE_SUCCESS = 'GET_USER_QR_CODE_SUCCESS';
export const GET_USER_QR_CODE_FAILURE = 'GET_USER_QR_CODE_FAILURE';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_START = 'LOGIN_START';
export const LOGOUT_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const GENERATE_CAPTCHA_START = 'GENERATE_CAPTCHA_START';
export const GENERATE_CAPTCHA_SUCCESS = 'GENERATE_CAPTCHA_SUCCESS';
export const GENERATE_CAPTCHA_FAILURE = 'GENERATE_CAPTCHA_FAILURE';
export const RELOAD_CAPTCHA_START = 'RELOAD_CAPTCHA_START';
export const RELOAD_CAPTCHA_SUCCESS = 'RELOAD_CAPTCHA_SUCCESS';
export const RELOAD_CAPTCHA_FAILURE = 'RELOAD_CAPTCHA_FAILURE';
export const GET_SMS_CODE_START = 'GET_SMS_CODE_START';
export const GET_SMS_CODE_SUCCESS = 'GET_SMS_CODE_SUCCESS';
export const GET_SMS_CODE_FAILURE = 'GET_SMS_CODE_FAILURE';
export const REGISTER_PHONE_NUMBER_START = 'REGISTER_PHONE_NUMBER_START';
export const REGISTER_PHONE_NUMBER_SUCCESS = 'REGISTER_PHONE_NUMBER_SUCCESS';
export const REGISTER_PHONE_NUMBER_FAILURE = 'REGISTER_PHONE_NUMBER_FAILURE';
export const SET_PHONE_REGISTRATION_MODAL_OPEN = 'SET_PHONE_REGISTRATION_MODAL_OPEN';
