import styled from 'styled-components';
import { colors } from '../modules/constants/theme';

const FullWidthBanner = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  text-align: center;
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 2rem 0;
`;

export default FullWidthBanner;
