import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@atlaskit/button';
import { FieldTextStateless } from '@atlaskit/field-text';

// import SmsCode from '../components/SmsCode';
import ErrorText from '../components/ErrorText';
import { getSmsCodeWithPhoneNumber, registerPhoneNumber } from '../actions/user';


class SmsCodeInputContainer extends Component {
  static propTypes = {
    getSmsCodeWithPhoneNumber: PropTypes.func.isRequired,
    registerPhoneNumber: PropTypes.func.isRequired,
    isHidden: PropTypes.bool.isRequired,
    statusCode: PropTypes.number.isRequired,
    serverError: PropTypes.string.isRequired,
  };

  state = {
    phoneNumberToken: '',
    nums: 60,
    isReSendButtonDisabled: true,
  };

  componentDidMount() {
    this.clock = setInterval(
      () => this.doLoop(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.clock);
  }

  doLoop() {
    this.setState({
      nums: this.state.nums - 1,
    });

    if (this.state.nums === 1) {
      clearInterval(this.clock);
      this.setState({
        isReSendButtonDisabled: false,
      });
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();

    this.props.registerPhoneNumber({
      phoneCountryCode: this.props.phoneCountryCode,
      phoneNumber: this.props.phoneNumber,
      phoneNumberToken: this.state.phoneNumberToken,
    });
  };

  onButtonClick = () => {
    this.props.getSmsCodeWithPhoneNumber({
      phoneCountryCode: this.props.phoneCountryCode,
      phoneNumber: this.props.phoneNumber,
    });
  };

  onInputChange = key => (e) => {
    this.setState({ [key]: e.target.value });
  };

  renderErrorMessage = (statusCode) => {
    switch (statusCode) {
      case 406:
        return <ErrorText>{this.props.serverError}</ErrorText>;
      default:
        return null;
    }
  }

  render() {
    if (this.props.isHidden) { return null; }

    return (
      <form onSubmit={this.onFormSubmit}>
        <FieldTextStateless
          label="Please input vefidication code:"
          name="phoneNumberToken"
          onChange={this.onInputChange('phoneNumberToken')}
        />
        <Button
          onClick={this.onButtonClick}
          isDisabled={this.state.isReSendButtonDisabled}
        >
          {this.state.nums} Send again
        </Button>
        <p>
          <Button type="submit"
            appearance="primary"
            shouldFitContainer
          >
            Register
          </Button>
        </p>
        {this.renderErrorMessage(this.props.statusCode)}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isHidden: !state.phoneRegistration.shouldShowSmsCodeInput,
  phoneCountryCode: state.phoneRegistration.phoneCountryCode,
  phoneNumber: state.phoneRegistration.phoneNumber,
  statusCode: state.phoneRegistration.statusCode,
  serverError: state.phoneRegistration.serverError,
  getSmsStatusCode: state.phoneRegistration.getSmsStatusCode,
  getSmsFailureServerError: state.phoneRegistration.getSmsFailureServerError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSmsCodeWithPhoneNumber,
  registerPhoneNumber,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmsCodeInputContainer);
