import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';

import { logout } from '../actions/user';

const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 1rem;
  text-align: center;
`;

class LogoutContainer extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Wrapper>
        <Button appearance="link" iconBefore={<SignOutIcon />} onClick={this.props.logout}>
        Logout
        </Button>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutContainer);
