import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from '@atlaskit/page';
import Modal from '@atlaskit/modal-dialog';

import { getUserInfo } from '../account/actions/user';
import LogoutContainer from '../account/containers/Logout';
import PhoneRegistrationContainer from '../account/containers/PhoneRegistration';
import ReferralContainer from '../account/containers/Referral';
import RemainingTrafficContainer from '../traffic/containers/RemainingTraffic';
import TrafficPlanContainer from '../traffic/containers/TrafficPlan';
import UpcomingTrafficContainer from '../traffic/containers/UpcomingTraffic';
import FullWidthBanner from '../../components/FullWidthBanner';


import './home.css';


class Home extends Component {
  static propTypes = {
    getUserInfo: PropTypes.func.isRequired,
  };

  state = {
    registeredEmail: '',
  };

  componentDidMount() {
    // Fetch data at the first mount.
    this.props.getUserInfo();
  }

  closeWelcomeModal = () => {
    window.localStorage.removeItem('registeredEmail');
    this.setState({ registeredEmail: null });
  };

  render() {
    const {
      registeredEmail,
    } = this.state;
    return (
      <Page>
        {registeredEmail && (
          <Modal heading="Successfully Registered!" onClose={this.closeWelcomeModal}>
            <p>Please check your email <b>{registeredEmail}</b> to activate your account.</p>
          </Modal>)
        }
        <FullWidthBanner>
          <RemainingTrafficContainer />
          <PhoneRegistrationContainer />
          <ReferralContainer />
        </FullWidthBanner>
        <div className="page-container">
          <UpcomingTrafficContainer />
          <TrafficPlanContainer />
          <LogoutContainer />
        </div>
      </Page>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserInfo,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
