import {
  GENERATE_CAPTCHA_START,
  GENERATE_CAPTCHA_SUCCESS,
  GENERATE_CAPTCHA_FAILURE,
  RELOAD_CAPTCHA_START,
  RELOAD_CAPTCHA_SUCCESS,
  RELOAD_CAPTCHA_FAILURE,
} from '../../constants/actionTypes';
import apiClient from '../../../utils/apiClient';

export const generateCaptchaId = () => async (dispatch) => {
  dispatch({
    type: GENERATE_CAPTCHA_START,
  });

  try {
    const result = await apiClient.post('/captcha/new');

    return dispatch({
      type: GENERATE_CAPTCHA_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    return dispatch({
      type: GENERATE_CAPTCHA_FAILURE,
      error,
    });
  }
};

// Deprecated:
// Since React cannot reload img with same captchaID. Keep using
// `generateCaptchaId` to load a new captcha image.
export const reloadCaptcha = captchaID => async (dispatch) => {
  dispatch({
    type: RELOAD_CAPTCHA_START,
  });

  try {
    await apiClient.post(`/captcha/reload/${captchaID}`);

    return dispatch({
      type: RELOAD_CAPTCHA_SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: RELOAD_CAPTCHA_FAILURE,
      error,
    });
  }
};
