import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getUserQrCode } from '../actions/user';
import Referral from '../components/Referral';

class ReferralContainer extends Component {
  static propTypes = {
    getUserQrCode: PropTypes.func.isRequired,
    imageData: PropTypes.string,
    isHidden: PropTypes.bool,
    url: PropTypes.string,
  };

  static getDerivedStateFromProps(props) {
    // Fetch data when Button is shown and no QR image data.
    if (!props.isHidden && !props.imageData) {
      props.getUserQrCode();
    }
    return {};
  }

  state = {};

  render() {
    if (this.props.isHidden) { return null; }

    return (
      <Referral
        url={this.props.url}
        imageData={this.props.imageData}
      />
    );
  }
}

const mapStateToProps = state => ({
  imageData: state.user.qrCode.imageData,
  isHidden: !state.phoneRegistration.shouldShowReferral,
  url: state.user.qrCode.url,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserQrCode,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralContainer);
