import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import LayerManager from '@atlaskit/layer-manager';


const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!window.localStorage.getItem('token')) {
        window.location.href = '/login';
        return null;
      }
      return <LayerManager><Component {...props} /></LayerManager>;
    }}
  />
);

PrivateRoute.propTypes = {
  // @TODO further update user object details
  user: PropTypes.shape({}),
  component: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  user: null,
};
const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
