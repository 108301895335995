import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { register } from '../actions/user';
import { generateCaptchaId } from '../actions/captcha';

import CreateAccountForm from '../components/CreateAccountForm';
import Captcha from '../components/Captcha';

class CreateAccount extends Component {
  static propTypes = {
    generateCaptchaId: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    captchaId: PropTypes.string,
    serverError: PropTypes.string,
  };

  static defaultProps = {
    captchaId: null,
    serverError: null,
  };

  componentDidMount() {
    this.props.generateCaptchaId();
  }

  render() {
    return (
      <CreateAccountForm
        handleSubmit={this.props.register}
        handleCaptchaReload={this.props.generateCaptchaId}
        captcha={<Captcha captchaId={this.props.captchaId} />}
        ID={this.props.captchaId}
        serverError={this.props.serverError}
      />
    );
  }
}

const mapStateToProps = state => ({
  captchaId: state.captcha.captchaId,
  serverError: state.user.registerFailureServerError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  generateCaptchaId,
  register,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAccount);
