import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatGb } from '../../../utils/formatGb';


class RemainingTrafficContainer extends Component {
  static propTypes = {
    remainingTraffic: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div>
        <p>Remaining Traffic</p>
        <h2>{formatGb(this.props.remainingTraffic, 3)} GB</h2>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  remainingTraffic: state.user.remainingTraffic,
});

export default connect(mapStateToProps)(RemainingTrafficContainer);
