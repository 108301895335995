/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import paypal from 'paypal-checkout';

import apiClient from '../../../utils/apiClient';

class PayPalCheckoutButton extends Component {
  static propTypes = {
    planId: PropTypes.string.isRequired,
  };

  render() {
    const env = process.env.REACT_APP_PAYMENT_ENV || 'production';

    const payment = async (data, actions) => {
      const result = await apiClient.post('/payment/purchase_plan', {
        PaymentMethod: 'PayPal',
        PlanID: this.props.planId,
      });
      return result.data.PaymentToken;
    };

    const onAuthorize = (data, actions) => {
      let isWaiting = true;
      let lastStatus = 0;
      const xhr = new XMLHttpRequest();

      while (isWaiting) {
        isWaiting = false;
        xhr.open('GET', data.returnUrl, false);
        xhr.send();
        lastStatus = xhr.status;
        isWaiting = lastStatus === 202; // Don't need to wait on client, as the server will do the rate-limit for you if the status is 202
      }

      if (lastStatus === 200) {
        // Now consider the payment is completed
        alert('Top up successfully!');

        // Reload the page to get the latest information
        // @todo: use action to handle the success case
        setTimeout(() => {
          window.location.href = window.location.href;
        }, 2000);
      }
    };
    const PayPalButton = paypal.Button.driver('react', { React, ReactDOM });

    return (
      <PayPalButton
        env={env}
        payment={payment}
        commit
        onAuthorize={onAuthorize}
      />
    );
  }
}

export default PayPalCheckoutButton;
