import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import captcha from './account/reducers/captcha';
import plan from './traffic/reducers/plan';
import phoneRegistration from './account/reducers/phoneRegistration';
import user from './account/reducers/user';

export default combineReducers({
  routing: routerReducer,
  captcha,
  phoneRegistration,
  plan,
  user,
});
