import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@atlaskit/button';

import Modal from '@atlaskit/modal-dialog';
import Flex, { FlexItem } from 'styled-flex-component';

import { getPlans } from '../actions/plan';
import EmailWarningBanner from '../components/EmailWarningBanner';
import PayPalCheckoutButton from '../components/PayPalCheckoutButton';
import StripeCheckoutButton from '../components/StripeCheckoutButton';
import { formatGb } from '../../../utils/formatGb';


const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

class TrafficPlanContainer extends Component {
  static propTypes = {
    getPlans: PropTypes.func.isRequired,
    plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isEmailConfirmed: PropTypes.bool,
  };

  state = {
    isTopUpModalOpen: false,
    selectedPlan: {},
  };

  componentDidMount() {
    // Fetch data at the first mount.
    this.props.getPlans();
  }

  selectPlan = plan => this.setState({
    isTopUpModalOpen: true,
    selectedPlan: plan,
  });

  closeTopUpModal = () => this.setState({ isTopUpModalOpen: false });

  render() {
    const {
      isTopUpModalOpen,
      selectedPlan,
    } = this.state;
    return (
      <section>
        <h4>Traffic Plans:</h4>
          <p>Please choose a plan to top up your traffic.</p>
          {this.props.isEmailConfirmed === false && (
            <EmailWarningBanner />
          )}
          <Flex center wrap="wrap" justifyBetween>
            {
              this.props.plans.map(plan => (
                <FlexItem key={plan.ID} >
                  <ButtonWrapper>
                    <Button
                      onClick={() => this.selectPlan(plan)}
                      isDisabled={!this.props.isEmailConfirmed}
                    >
                      {formatGb(plan.TrafficQuota)} GB / {plan.Currency}${plan.Price}
                    </Button>
                  </ButtonWrapper>
                </FlexItem>
              ))
            }
          </Flex>
          {isTopUpModalOpen && (
            <Modal heading="Top Up Item" onClose={this.closeTopUpModal} width={300}>
              <h2 className="text-center">
                {
                  // eslint-disable-next-line max-len
                  formatGb(selectedPlan.TrafficQuota)} GB / {selectedPlan.Currency}${selectedPlan.Price
                }
              </h2>
              <div className="pay-btn-wrapper">
                <StripeCheckoutButton planId={selectedPlan.ID} />
              </div>
              <div className="pay-btn-wrapper">
                <PayPalCheckoutButton planId={selectedPlan.ID} />
              </div>
            </Modal>
          )}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  plans: state.plan.plans,
  isEmailConfirmed: state.user.isEmailConfirmed,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPlans,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrafficPlanContainer);
