import React, { Component } from 'react';


class TopUp extends Component {
  componentDidMount() {
    // Fetch data at the first mount.
  }

  render() {
    return (
      <div>
        TopUp
      </div>
    );
  }
}

export default TopUp;
