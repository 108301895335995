import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getPlanHistory } from '../actions/plan';
import UpcomingTrafficSection from '../components/UpcomingTrafficSection';


class UpcomingTrafficContainer extends Component {
  static propTypes = {
    getPlanHistory: PropTypes.func.isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  componentDidMount() {
    // Fetch data at the first mount.
    this.props.getPlanHistory();
  }

  render() {
    if (this.props.subscriptions.length <= 0) { return null; }

    return (
      <UpcomingTrafficSection
        subscriptions={this.props.subscriptions}
      />
    );
  }
}

const mapStateToProps = state => ({
  subscriptions: state.plan.subscriptions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPlanHistory,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingTrafficContainer);
