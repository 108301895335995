import {
  GET_USER_INFO_SUCCESS,
  GET_SMS_CODE_START,
  GET_SMS_CODE_SUCCESS,
  GET_SMS_CODE_FAILURE,
  REGISTER_PHONE_NUMBER_SUCCESS,
  REGISTER_PHONE_NUMBER_FAILURE,
  SET_PHONE_REGISTRATION_MODAL_OPEN,
} from '../../constants/actionTypes';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function phoneRegistrationReducer(state = initialState.phoneRegistration, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case GET_USER_INFO_SUCCESS:
      newState.shouldShowPhoneRegistration = !action.data.PhoneConfirmed;
      newState.shouldShowReferral = action.data.PhoneConfirmed;
      return newState;

    case GET_SMS_CODE_START:
      newState.isLoading = true;
      newState.phoneCountryCode = action.data.phoneCountryCode;
      newState.phoneNumber = action.data.phoneNumber;
      newState.shouldShowPhoneInput = true;
      return newState;

    case GET_SMS_CODE_SUCCESS:
      newState.isLoading = false;
      newState.shouldShowPhoneInput = false;
      newState.shouldShowSmsCodeInput = true;
      return newState;

    case GET_SMS_CODE_FAILURE:
      newState.isLoading = false;
      newState.shouldShowPhoneInput = true;
      newState.shouldShowSmsCodeInput = false;
      newState.getSmsStatusCode = action.error.status;
      newState.getSmsFailureServerError = action.error.data[0].message;
      return newState;

    case REGISTER_PHONE_NUMBER_SUCCESS:
      newState.shouldShowSmsCodeInput = false;
      newState.shouldShowPhoneRegistration = false;
      newState.shouldShowReferral = true;
      newState.shouldShowSuccess = true;
      return newState;

    case REGISTER_PHONE_NUMBER_FAILURE:
      newState.shouldShowSmsCodeInput = true;
      newState.shouldShowSuccess = false;
      newState.statusCode = action.error.status;
      newState.serverError = action.error.data[0].message;
      return newState;

    case SET_PHONE_REGISTRATION_MODAL_OPEN:
      newState.isModalOpen = action.data;
      return newState;

    default:
      return state;
  }
}
