import React from 'react';
import PropTypes from 'prop-types';

import { formatGb, ONE_GB } from '../../../utils/formatGb';


const UpcomingTrafficSection = (props) => (
  <section>
    <h4>Upcoming traffic to be used:</h4>
    <div className="button-group">
      {
        // Remove first item because it's in used
        props.subscriptions.map(subscription => (
          <div key={subscription.SubscriptionID}>
            {formatGb(
            subscription.RemainingQuota,
            (subscription.RemainingQuota < ONE_GB) ? 1 : 0,
            )} GB
          </div>
        ))
      }
    </div>
  </section>
);

UpcomingTrafficSection.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};


export default UpcomingTrafficSection;
