import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import FieldText from '@atlaskit/field-text';


const ReferralWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

const QRImg = styled.img`
  width: 100%;

  @media (min-width: 420px) {
    height: 40vh;
    width: 40vh;
    display: block;
    margin: auto;
  }
`;

class Referral extends Component {
  static propTypes = {
    url: PropTypes.string,
    imageData: PropTypes.string,
  };

  state = {
    isModalOpen: false,
  };

  openModal = () => this.setState({
    isModalOpen: true,
  });

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <ReferralWrapper>
        <Button
          appearance="primary"
          onClick={this.openModal}
        >
          Share
        </Button>
        {this.state.isModalOpen && (
          <Modal
            heading="Share"
            onClose={this.closeModal}
            actions={[
              { text: 'Dismiss', appearance:'secondary', onClick: this.closeModal }
            ]}
          >
            <p>Once your friend register via your referral QR code, you both can get 0.1 GB free traffic!</p>
            <QRImg src={this.props.imageData} alt="Your referral QR code" />
            <FieldText label={"You can also share the link below"} isReadOnly shouldFitContainer value={this.props.url} />
          </Modal>)
        }
      </ReferralWrapper>
    );
  }
}

export default Referral;
