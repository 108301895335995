import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import store, { history } from './store';
import Routes from './routes';


const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <main>
        <Routes />
      </main>
    </ConnectedRouter>
  </Provider>
);

export default App;
