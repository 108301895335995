import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FieldTextStateless } from '@atlaskit/field-text';
import Button from '@atlaskit/button';
import AppIcon from './AppIcon';
import ErrorText from './ErrorText';

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    statusCode: PropTypes.number.isRequired,
  };

  state = {
    username: '',
    password: '',
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    this.props.handleSubmit({
      username: this.state.username,
      password: this.state.password,
    });
  };

  onInputChange = key => (e) => {
    this.setState({ [key]: e.target.value });
  };

  renderErrorMessage = (statusCode) => {
    switch (statusCode) {
      case 401:
        return <ErrorText>Username or password is incorrect.</ErrorText>;
      case 500:
        return <ErrorText>Something went wrong, please try again later.</ErrorText>;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="absolute-center-container form-wrapper">
        <form className="form" onSubmit={this.onFormSubmit}>
          <AppIcon />
          <h1>Login</h1>
          <FieldTextStateless
            autoFocus
            label="Username:"
            required
            name="username"
            shouldFitContainer
            onChange={this.onInputChange('username')}
          />
          <FieldTextStateless
            label="Password:"
            type="password"
            required
            name="password"
            shouldFitContainer
            onChange={this.onInputChange('password')}
          />
          <p>
            <Button type="submit" appearance="primary" shouldFitContainer>
              Login
            </Button>
          </p>
          {this.renderErrorMessage(this.props.statusCode)}
          <p hidden>
            Don&apos;t have an account? <Link href="/register" to="/register">Register</Link>
          </p>
        </form>
      </div>
    );
  }
}

export default LoginForm;
