import {
  GENERATE_CAPTCHA_SUCCESS,
} from '../../constants/actionTypes';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function usersReducer(state = initialState.captcha, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case GENERATE_CAPTCHA_SUCCESS:
      newState.captchaId = action.data.ID;
      return newState;
    default:
      return state;
  }
}
