import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';

import { setPhoneRegistrationModalOpen } from '../actions/user';


const Wrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

class PhoneRegistrationModalContainer extends Component {
  static propTypes = {
    setPhoneRegistrationModalOpen: PropTypes.func.isRequired,
    shouldShowButton: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  }

  openModal = () => {
    this.props.setPhoneRegistrationModalOpen(true);
  }

  closeModal = () => {
    this.props.setPhoneRegistrationModalOpen(false);
  }

  render() {
    return (
      <Wrapper>
        {this.props.shouldShowButton && (
          <Button
            appearance="primary"
            onClick={this.openModal}
          >
            Get 0.1 GB
          </Button>
        )}

        {this.props.isModalOpen && (
          <Modal
            heading="Phone Registration"
            onClose={this.closeModal}
            width={500}
          >
            {this.props.children}
          </Modal>
        )}
      </Wrapper>
    );
  }
}


const mapStateToProps = state => ({
  shouldShowButton: state.phoneRegistration.shouldShowPhoneRegistration,
  isModalOpen: state.phoneRegistration.isModalOpen,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setPhoneRegistrationModalOpen,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneRegistrationModalContainer);
