import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './modules/account/containers/PrivateRoute';
import LoginContainer from './modules/account/containers/Login';
import Home from './modules/sections/Home';
import TopUp from './modules/top-up/TopUp';
import CreateAccount from './modules/account/containers/CreateAccount';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={LoginContainer} />
      <Route path="/register" component={CreateAccount} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/top-up" component={TopUp} />
    </Switch>
  );
}
