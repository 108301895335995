import axios from 'axios';

if (window.localStorage.getItem('token')) {
  axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT || 30000,
});

export default apiClient;
