import {
  BUY_PLAN_SUCCESS,
  GET_PLAN_SUCCESS,
  GET_PLAN_HISTORY_SUCCESS,
} from '../../constants/actionTypes';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function plansReducer(state = initialState.plan, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case BUY_PLAN_SUCCESS:
      // newState.paymentUrl = action.data.PaymentURL;
      newState.paymentToken = action.data.PaymentToken;
      return newState;

    case GET_PLAN_SUCCESS:
      newState.plans = action.data;
      return newState;

    case GET_PLAN_HISTORY_SUCCESS:
      newState.subscriptions = action.data;
      return newState;
    default:
      return state;
  }
}
