import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import StripeCheckout from 'react-stripe-checkout';

import apiClient from '../../../utils/apiClient';


class StripeCheckoutButton extends Component {
  static propTypes = {
    planId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  onToken = async (token) => {
    const result = await apiClient.post('/payment/purchase_plan', {
      PaymentMethod: 'Stripe',
      PlanID: this.props.planId,
    });

    const resultConfirm = await apiClient.get(`/payment/confirm/${result.data.PaymentJWT}?stripeToken=${token.id}`);

    if (resultConfirm.status === 200) {
      // Now consider the payment is completed
      alert('Top up successfully!');

      setTimeout(() => {
        // @fixme: Hanlding UI state properly
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
      }, 2000);
    }
  }

  render() {
    return (
      <StripeCheckout
        token={this.onToken}
        email={this.props.email}
        stripeKey={process.env.REACT_APP_STRIPE_KEY}
        panelLabel="Pay with Stripe"
        currency="USD"
      />
    );
  }
}

const mapStateToProps = state => ({
  email: state.user.email,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeCheckoutButton);
