import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CaptchaImg = styled.img`
  width: 100%;
`;

const Captcha = (props) => {
  if (!props.captchaId) { return null; }

  return (
    <CaptchaImg src={`${process.env.REACT_APP_API_BASE_URL}/captcha/${props.captchaId}`} alt="" />
  );
};

Captcha.propTypes = {
  captchaId: PropTypes.string,
};

Captcha.defaultProps = {
  captchaId: null,
};

export default Captcha;
