export default {
  user: {
    email: '',
    isLoggedIn: false,
    name: '',
    remainingTraffic: 0,
    isEmailConfirmed: null,
    isPhoneConfirmed: null,
    // @todo: refactor error handling for API request
    registerFailureServerError: null,
    statusCode: 0,
    qrCode: {
      url: null,
      imageData: null,
    }
  },
  captcha: {
    captchaId: null,
  },
  phoneRegistration: {
    shouldShowPhoneRegistration: false,
    shouldShowPhoneInput: true,
    phoneCountryCode: '',
    phoneNumber: '',
    isLoading: false,
    shouldShowSmsCodeInput: false,
    getSmsStatusCode: 0,
    getSmsFailureServerError: '',
    statusCode: 0,
    serverError: '',
    isModalOpen: false,
    shouldShowSuccess: false,
    shouldShowReferral: false,
  }
};
