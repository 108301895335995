import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FieldTextStateless } from '@atlaskit/field-text';
import Button from '@atlaskit/button';
import AppIcon from './AppIcon';
import ErrorText from './ErrorText';
import getUrlParams from '../../../utils/url';


const FormWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  margin: auto;
`;

class CreateAccountForm extends Component {
  static propTypes = {
    captcha: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCaptchaReload: PropTypes.func.isRequired,
    ID: PropTypes.node,
    serverError: PropTypes.string,
  };

  static defaultProps = {
    serverError: null,
    ID: null,
  }

  state = {
    captchaAnswer: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    isPasswordInvalid: false,
  };

  // @todo handle form vaildation
  onFormSubmit = (e) => {
    e.preventDefault();
    this.props.handleSubmit({
      username: this.state.username,
      password: this.state.password,
      captchaAnswer: this.state.captchaAnswer,
      email: this.state.email,
      name: '', // Pass dummy value for name field
      captchaID: this.props.ID,
      aff: getUrlParams(window.location.search)['aff'],
    });
  };

  onInputChange = key => (e) => {
    this.setState({ [key]: e.target.value });
  };

  onConfirmPasswordInputBlur = () => {
    this.setState({
      isPasswordInvalid: !this.state.password.match(this.state.confirmPassword),
    });
  }

  render() {
    return (
      <FormWrapper>
        <form className="form" onSubmit={this.onFormSubmit}>
          <AppIcon />
          <h1>Register</h1>
          <FieldTextStateless label="Username:"
            required
            name="username"
            shouldFitContainer
            value={this.state.userName}
            onChange={this.onInputChange('username')}
          />
          <FieldTextStateless label="E-mail:"
            required
            type="email"
            name="email"
            shouldFitContainer
            value={this.state.email}
            onChange={this.onInputChange('email')}
          />
          <FieldTextStateless label="Password:"
            type="password"
            required
            name="password"
            shouldFitContainer
            value={this.state.passsword}
            onChange={this.onInputChange('password')}
          />
          <FieldTextStateless label="Confirm Password:"
            type="password"
            required
            name="confirmPassword"
            shouldFitContainer
            onChange={this.onInputChange('confirmPassword')}
            onBlur={this.onConfirmPasswordInputBlur}
            value={this.state.confirmPassword}
          />
          <Flex alignEnd>
            <FlexItem>{this.props.captcha}</FlexItem>
            <FlexItem>
              <Button appearance="link" onClick={this.props.handleCaptchaReload}>
                Reload Image
              </Button>
            </FlexItem>
          </Flex>
          <FieldTextStateless label="Please input the number of above image"
            name="captchaAnswer"
            required
            shouldFitContainer
            onChange={this.onInputChange('captchaAnswer')}
          />
          <p>
            <Button type="submit" appearance="primary" shouldFitContainer>
              Register
            </Button>
          </p>
          {this.state.isPasswordInvalid && (
            <ErrorText>Password and Confirm Password are not matched.</ErrorText>
          )}
          {this.props.serverError && (
            <ErrorText>{this.props.serverError}</ErrorText>
          )}
          <p>
            Already has an account? <Link to="/login" href="/login" >Log in</Link>
          </p>
        </form>
      </FormWrapper>

    );
  }
}
export default CreateAccountForm;
