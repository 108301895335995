import {
  BUY_PLAN_START,
  BUY_PLAN_SUCCESS,
  BUY_PLAN_FAILURE,
  GET_PLAN_START,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  GET_PLAN_HISTORY_START,
  GET_PLAN_HISTORY_SUCCESS,
  GET_PLAN_HISTORY_FAILURE,
} from '../../constants/actionTypes';
import apiClient from '../../../utils/apiClient';

export const buyPlan = payload => async (dispatch) => {
  dispatch({
    type: BUY_PLAN_START,
  });

  try {
    const result = await apiClient.post('/payment/purchase_plan', {
      PaymentMethod: 'PayPal',
      PlanID: payload,
    });

    return dispatch({
      type: BUY_PLAN_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    return dispatch({
      type: BUY_PLAN_FAILURE,
      error,
    });
  }
};

export const getPlans = () => async (dispatch) => {
  dispatch({
    type: GET_PLAN_START,
  });

  try {
    const result = await apiClient.get('/plan');
    return dispatch({
      type: GET_PLAN_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    return dispatch({
      type: GET_PLAN_FAILURE,
      error,
    });
  }
};

export const getPlanHistory = () => async (dispatch) => {
  dispatch({
    type: GET_PLAN_HISTORY_START,
  });

  try {
    const result = await apiClient.get('/user/me/subscriptions?filter=upcoming');

    return dispatch({
      type: GET_PLAN_HISTORY_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    return dispatch({
      type: GET_PLAN_HISTORY_FAILURE,
      error,
    });
  }
};
