import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@atlaskit/button';
// import PhoneInput from 'react-telephone-input/lib/withStyles';
// import PhoneInput from 'react-telephone-input';
import PhoneInput from 'react-phone-input-2';

import ErrorText from '../components/ErrorText';
import { getSmsCodeWithPhoneNumber } from '../actions/user';

//import './phoneInput.css';

const ButtonWrapper = styled.p`
  margin-top: 3rem;
`;

class PhoneInputContainer extends Component {
  static propTypes = {
    getSmsCodeWithPhoneNumber: PropTypes.func.isRequired,
    isHidden: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    statusCode: PropTypes.number.isRequired,
    serverError: PropTypes.string.isRequired,
  };

  state = {
    phoneNumber: '',
  };

  onFormSubmit = (e) => {
    e.preventDefault();

    let phone = this.state.phoneNumber;
    phone = phone.substr(1, phone.length - 1);
    const arr = phone.split(/ (.*)/);

    this.props.getSmsCodeWithPhoneNumber({
      phoneCountryCode: arr[0],
      phoneNumber: arr[1]+arr[2],
    });
  };

  renderErrorMessage = (statusCode) => {
    switch (statusCode) {
      case 406:
        return <ErrorText>{this.props.serverError}</ErrorText>;
      default:
        return null;
    }
  }

  render() {
    if (this.props.isLoading) { return <p>Sending...</p>}

    if (this.props.isHidden) { return null; }

    return (
      <form onSubmit={this.onFormSubmit}>
        <p>You will get 100MB free traffic after register your phone number.</p>
        <PhoneInput
          defaultCountry={'cn'}
          onlyCountries={['cn','hk']}
          placeholder=''
          localization={{'China': 'China 中國', 'Hong Kong': 'Hong Kong 香港'}}
          value={this.state.phoneNumber}
          onChange={phoneNumber => this.setState({ phoneNumber })}
          // dropdownStyle={dropdownStyle}
          //liststyle={liststyle}
          /* defaultCountry={'cu'}
          value={this.state.phoneNumber}
          onChange={phoneNumber => this.setState({ phoneNumber })}
          flagsImagePath=""
          onlyCountries={[
            {
              name: 'China (中国)',
              iso2: 'cn',
              dialCode: '86',
              format: '+.. (...) .......',
            },
            {
              name: 'Hong Kong (香港)',
              iso2: 'hk',
              dialCode: '852',
              format: '+... ........',
            },
          ]}
          */
        />
        <ButtonWrapper>
          <Button type="submit"
            appearance="primary"
            shouldFitContainer
          >
            Send
          </Button>
        </ButtonWrapper>
        {this.renderErrorMessage(this.props.statusCode)}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isHidden: !state.phoneRegistration.shouldShowPhoneInput,
  isLoading: state.phoneRegistration.isLoading,
  statusCode: state.phoneRegistration.getSmsStatusCode,
  serverError: state.phoneRegistration.getSmsFailureServerError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSmsCodeWithPhoneNumber,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneInputContainer);
