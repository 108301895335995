export const colors = {
  primary: '#0d70bc',
  secondary: '#2aabe3',
  white: '#fff',
  alert: '#ff334c',
  warning: '#ffab00',
  'grey-darker': '#e4e5e6',
  'grey-dark': '#f9f9f9',
  grey: '#818182',
  'grey-light': '#dcdcdc',
  'grey-lighter': '#e9e9e9',
};

export const fonts = {
  xlarge: '1.4rem',
  large: '1.1rem',
  normal: '1rem',
  small: '0.8rem',
};
