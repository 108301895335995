import React, { Component } from 'react';

import PhoneRegistrationModalContainer from './PhoneRegistrationModal';
import PhoneInputContainer from './PhoneInput';
import SmsCodeInputContainer from './SmsCodeInput';
import PhoneRegistrationSuccessContainer from './PhoneRegistrationSuccess';


class PhoneRegistrationContainer extends Component {
  render() {
    return (
      <PhoneRegistrationModalContainer>
        <PhoneInputContainer />
        <SmsCodeInputContainer />
        <PhoneRegistrationSuccessContainer />
      </PhoneRegistrationModalContainer>
    );
  }
}

export default PhoneRegistrationContainer;
