import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  GET_USER_INFO_START,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  GET_USER_QR_CODE_START,
  GET_USER_QR_CODE_SUCCESS,
  GET_USER_QR_CODE_FAILURE,
  GET_SMS_CODE_START,
  GET_SMS_CODE_SUCCESS,
  GET_SMS_CODE_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_PHONE_NUMBER_START,
  REGISTER_PHONE_NUMBER_SUCCESS,
  REGISTER_PHONE_NUMBER_FAILURE,
  SET_PHONE_REGISTRATION_MODAL_OPEN,
} from '../../constants/actionTypes';
import apiClient from '../../../utils/apiClient';
import logger from '../../../utils/logger';


export const login = payload => async (dispatch) => {
  dispatch({
    type: LOGIN_START,
  });

  try {
    const result = await apiClient.post('/user/login', {
      Username: payload.username,
      Password: payload.password,
    });

    window.localStorage.setItem('token', result.data);
    window.location.href = '/';

    return dispatch({
      type: LOGIN_SUCCESS,
    });
  } catch (error) {
    logger(error);
    return dispatch({
      type: LOGIN_FAILURE,
      error: error.response,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_START,
  });

  try {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('registeredEmail');
    window.location.href = '/login';

    return dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: LOGOUT_FAILURE,
      error,
    });
  }
};

export const getUserInfo = () => async (dispatch) => {
  dispatch({
    type: GET_USER_INFO_START,
  });

  try {
    const result = await apiClient.get('/user/me');

    return dispatch({
      type: GET_USER_INFO_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    logger(error);
    return dispatch({
      type: GET_USER_INFO_FAILURE,
      error,
    });
  }
};


export const register = payload => async (dispatch) => {
  dispatch({
    type: REGISTER_START,
  });

  try {
    const result = await apiClient.post('/user/register', {
      Aff: payload.aff,
      CaptchaAnswer: payload.captchaAnswer,
      CaptchaID: payload.captchaID,
      Email: payload.email,
      Name: payload.name,
      Username: payload.username,
      Password: payload.password,
    });
    window.localStorage.setItem('token', result.data);
    window.localStorage.setItem('registeredEmail', payload.email);
    window.location.href = '/';

    return dispatch({
      type: REGISTER_SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: REGISTER_FAILURE,
      error: error.response.data,
    });
  }
};

export const getSmsCodeWithPhoneNumber = payload => async (dispatch) => {
  dispatch({
    type: GET_SMS_CODE_START,
    data: payload,
  });

  try {
    const result = await apiClient.put('/user/me', {
      PhoneCountryCode: payload.phoneCountryCode,
      PhoneNumber: payload.phoneNumber,
    });
    return dispatch({
      type: GET_SMS_CODE_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    logger(error);
    return dispatch({
      type: GET_SMS_CODE_FAILURE,
      error: error.response,
    });
  }
};

export const registerPhoneNumber = payload => async (dispatch) => {
  dispatch({
    type: REGISTER_PHONE_NUMBER_START,
  });

  try {
    const result = await apiClient.put('/user/me', {
      PhoneCountryCode: payload.phoneCountryCode,
      PhoneNumber: payload.phoneNumber,
      PhoneNumberToken: payload.phoneNumberToken,
    });
    return dispatch({
      type: REGISTER_PHONE_NUMBER_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    logger(error);
    return dispatch({
      type: REGISTER_PHONE_NUMBER_FAILURE,
      error: error.response,
    });
  }
};

export const getUserQrCode = () => async (dispatch) => {
  dispatch({
    type: GET_USER_QR_CODE_START,
  });

  try {
    const result = await apiClient.get('/user/me/aff_qr', {
      headers: {
        'Accept': 'application/json',
      }
    });
    return dispatch({
      type: GET_USER_QR_CODE_SUCCESS,
      data: result.data,
    });
  } catch (error) {
    logger(error);
    return dispatch({
      type: GET_USER_QR_CODE_FAILURE,
      error: error.response,
    });
  }
};

export const setPhoneRegistrationModalOpen = payload => (dispatch) => {
  return dispatch({
    type: SET_PHONE_REGISTRATION_MODAL_OPEN,
    data: payload,
  });
}
