import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { login } from '../actions/user';
import LoginForm from '../components/LoginForm';


class LoginContainer extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    statusCode: PropTypes.number.isRequired,
  };

  static getDerivedStateFromProps() {
    if (window.localStorage.getItem('token')) {
      window.location.href = '/';
    }
    return {};
  }

  state = {};

  render() {
    return (
      <LoginForm
        handleSubmit={this.props.login}
        statusCode={this.props.statusCode}
      />
    );
  }
}

const mapStateToProps = state => ({
  statusCode: state.user.statusCode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);
