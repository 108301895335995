import React from 'react';
import styled from 'styled-components';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Flex, { FlexItem } from 'styled-flex-component';

import { colors } from '../../constants/theme';


const WarningBanner = Flex.extend`
  background-color: ${colors.warning};
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const WarningBannerIcon = FlexItem.extend`
  margin-left: 1rem;
`;

const WarningBannerContent = FlexItem.extend`
  margin: 1rem;
`;

const WarningBannerHeading = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const EmailWarningBanner = () => (
  <WarningBanner alignCenter contentCenter>
    <WarningBannerIcon noShrink>
      <WarningIcon label="Warning" secondaryColor={colors.Y200} />
    </WarningBannerIcon>
    <WarningBannerContent>
      <WarningBannerHeading>Email not verified yet</WarningBannerHeading>
      <div>Please verify email address before buying any plans.</div>
    </WarningBannerContent>
  </WarningBanner>
);

export default EmailWarningBanner;
