import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@atlaskit/button';

import { setPhoneRegistrationModalOpen } from '../actions/user';

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  float: right;
`;

class PhoneRegistrationSuccessContainer extends Component {
  static propTypes = {
    setPhoneRegistrationModalOpen: PropTypes.func.isRequired,
    isHidden: PropTypes.bool.isRequired,
  };

  onButtonClick = () => {
    this.props.setPhoneRegistrationModalOpen(false);
  }

  render() {
    if (this.props.isHidden ) { return null; }

    return (
      <div>
        <h2>Congratulations!</h2>
        <p>Your phone number was registered successfully!</p>
        <p>0.1 GB has been added to your account.</p>
        <ButtonWrapper>
          <Button
            appearance="link"
            onClick={this.onButtonClick}
          >
            Dismiss
          </Button>
        </ButtonWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isHidden: !state.phoneRegistration.shouldShowSuccess,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setPhoneRegistrationModalOpen,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneRegistrationSuccessContainer);
