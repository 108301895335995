import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/logo.svg';

const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 230px;
  margin: auto;
  padding-right: 0.5rem;
`;

const AppIcon = () => <Logo src={logo} alt="Unibits" />;

export default AppIcon;
